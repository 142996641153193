import React from 'react';
import { WidgetConfigurationContext } from '../../common/configuration';
import { registerMiniFooterEvent, SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { Translator } from '../../common/sgwt-i18n';
import { cleanObject } from '../../common/sgwt-widgets-utils';
import { widgetize, widgetPropsBoundEvent } from '../../common/widgetize';
import {
  getCurrentUserConsent,
  IUserConsent,
  openDidomiUserPreferences,
  runWhenDidomiIsReady,
} from './components/cookies-consent';
import MiniFooter from './components/MiniFooter';
import translator from './shared/sgwt-mini-footer.i18n';
import { ISgwtMiniFooterProps } from './sgwt-mini-footer.types';

import './sgwt-mini-footer.scss';

export class SgwtMiniFooter extends React.Component<ISgwtMiniFooterProps> {
  static contextType = WidgetConfigurationContext;
  public static is = 'sgwt-mini-footer';
  private translator: Translator = translator;
  private propsBoundEvent = widgetPropsBoundEvent(SgwtMiniFooter.is);
  private onWidgetReady = (): void => {
    this.props.onReady({});
  };

  componentDidMount(): void {
    if (this.props.i18n) {
      for (const lang of Object.keys(this.props.i18n)) {
        this.translator.addMessages(lang, this.props.i18n[lang]);
      }
    }
    document.addEventListener(this.propsBoundEvent, this.onWidgetReady);
    startWidgetMonitoring(
      SgwtWidgetName.MINI_FOOTER,
      null,
      cleanObject({
        about: this.props.about,
        accessibilityCompliance: this.props.accessibilityCompliance,
        accessibilityLink: this.props.accessibilityLink,
        accessibilityUid: this.props.accessibilityUid,
        contactUsByHelpCenter: !!this.props.contactUsByHelpCenter,
        container: this.props.container,
        cookiesConsent: !!this.props.cookiesConsent,
        cookieConsentLanguage: this.props.cookieConsentLanguage,
        design: this.props.design,
        legalNotices: this.props.legalNotices ? 'yes' : 'no',
        logoLink: this.props.logoLink,
        mode: this.props.mode,
        noBorder: this.props.noBorder,
        type: this.props.type,
      }),
    );
    if (this.props.legalNotices) {
      registerMiniFooterEvent('legal-notices.custom-links', JSON.stringify(this.props.legalNotices));
    }
    if (this.props.accessibilityUid) {
      registerMiniFooterEvent('accessibility-uid', this.props.accessibilityUid);
    }
    if (this.props.accessibilityCompliance) {
      registerMiniFooterEvent('accessibility-compliance', this.props.accessibilityCompliance);
    }
    if (this.props.accessibilityLink) {
      registerMiniFooterEvent('accessibility-link', this.props.accessibilityLink);
    }
  }

  componentWillUnmount = (): void => {
    document.removeEventListener(this.propsBoundEvent, this.onWidgetReady);
  };

  private onAboutClicked = (): void => {
    this.props.onAboutClicked({});
  };

  private onAccessibilityClicked = (): void => {
    this.props.onAccessibilityClicked({});
  };

  // Display the Didomi User Preferences popup
  public displayUserConsentPopup = (): void => {
    openDidomiUserPreferences();
  };

  // Get the current user consent
  public getCurrentUserConsent = (): IUserConsent[] | null => {
    return getCurrentUserConsent();
  };

  // Get the current user consent
  public getCurrentUserConsentAsync = async (): Promise<IUserConsent[]> => {
    return new Promise<IUserConsent[]>((resolve) => {
      const consents: IUserConsent[] | null = getCurrentUserConsent();
      if (consents === null) {
        runWhenDidomiIsReady(() => {
          resolve(getCurrentUserConsent() || []);
        });
      } else {
        resolve(consents as IUserConsent[]);
      }
    });
  };

  // Get user content for a specific category.
  private getUserConsentForCategory = (consents: IUserConsent[], category: 'web-analytics' | 'video'): boolean => {
    // Keep only the consents on the dedicated category.
    const consentOnCategory: IUserConsent[] = consents.filter((c) => c.category === category);
    // Get the consent from the Vendor ("Matomo") and the Purpose ("Video").
    const consentOnVendorLevel = consentOnCategory.some((c) => c.type === 'vendor' && c.consent);
    const consentOnPurposeLevel = consentOnCategory.some((c) => c.type === 'purpose' && c.consent);
    // The consent is done ONLY if the user gives the consent on the Puropose AND the Vendor
    return consentOnVendorLevel && consentOnPurposeLevel;
  };

  // Get the User Content for Matomo usage.
  public getUserConsentOnMatomo = async (): Promise<boolean> => {
    const consents = await this.getCurrentUserConsentAsync();
    return this.getUserConsentForCategory(consents, 'web-analytics');
  };

  // Get the User Content for Qumu usage.
  public getUserConsentOnQumu = async (): Promise<boolean> => {
    const consents = await this.getCurrentUserConsentAsync();
    return this.getUserConsentForCategory(consents, 'video');
  };

  private emitUserConsentEvent = (
    eventName: 'user-consent-changed' | 'current-user-consent',
    consents: IUserConsent[],
  ) => {
    if (eventName === 'user-consent-changed') {
      this.props.onUserConsentChanged(consents);
    }
    if (eventName === 'current-user-consent') {
      this.props.onCurrentUserConsent(consents);
    }
  };

  render() {
    const {
      about,
      accessibilityCompliance,
      accessibilityLink,
      accessibilityUid,
      contactUs,
      contactUsByHelpCenter,
      container,
      cookiesConsent = false,
      cookieConsentLanguage,
      design,
      legalNotices,
      logoLink,
      logoLinkTitle,
      mode,
      noBorder,
      type,
    } = this.props;
    return (
      <MiniFooter
        about={about}
        accessibilityCompliance={accessibilityCompliance}
        accessibilityLink={accessibilityLink}
        accessibilityUid={accessibilityUid}
        contactUs={contactUs}
        contactUsByHelpCenter={contactUsByHelpCenter}
        container={container}
        cookiesConsent={cookiesConsent}
        cookieConsentLanguage={cookieConsentLanguage}
        design={design}
        emitUserConsentEvent={this.emitUserConsentEvent}
        legalNotices={legalNotices}
        logoLink={logoLink}
        logoLinkTitle={logoLinkTitle}
        mode={mode}
        noBorder={noBorder}
        onAboutClicked={() => this.onAboutClicked()}
        onAccessibilityClicked={() => this.onAccessibilityClicked()}
        translator={this.translator}
        type={type}
      />
    );
  }
}

widgetize(
  SgwtMiniFooter,
  SgwtMiniFooter.is,
  {
    attributes: [
      { name: 'about', type: 'string' },
      { name: 'accessibility-compliance', type: 'string' },
      { name: 'accessibility-link', type: 'string' },
      { name: 'accessibility-uid', type: 'string' },
      { name: 'contact-us', type: 'object' },
      { name: 'contact-us-by-help-center', type: 'string' },
      { name: 'container', type: 'string' },
      { name: 'cookies-consent', type: 'boolean' },
      { name: 'cookies-consent-language', type: 'string' },
      { name: 'design', type: 'string' },
      { name: 'cookie-consent-popup-language', type: 'string' },
      { name: 'i18n', type: 'object' },
      { name: 'legal-notices', type: 'object' },
      { name: 'logo-link', type: 'string' },
      { name: 'logo-link-title', type: 'string' },
      { name: 'mode', type: 'string' },
      { name: 'no-border', type: 'boolean' },
      { name: 'type', type: 'string' },
    ],
    events: [
      {
        name: `${SgwtMiniFooter.is}--ready`,
        functionName: 'onReady',
      },
      {
        name: `${SgwtMiniFooter.is}--about-link-clicked`,
        functionName: 'onAboutClicked',
      },
      {
        name: `${SgwtMiniFooter.is}--accessibility-link-clicked`,
        functionName: 'onAccessibilityClicked',
      },
      {
        name: `${SgwtMiniFooter.is}--user-consent-changed`,
        functionName: 'onUserConsentChanged',
      },
      {
        name: `${SgwtMiniFooter.is}--current-user-consent`,
        functionName: 'onCurrentUserConsent',
      },
    ],
    deferredFunctions: [
      'getCurrentUserConsent',
      'getCurrentUserConsentAsync',
      'getUserConsentOnMatomo',
      'getUserConsentOnQumu',
    ],
  },
  { shadow: false },
);
