import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';

export const SGLabel: React.FC<ITranslatorProps> = (props: ITranslatorProps) => {
  return (
    <span className="h6 mb-0 font-weight-normal fw-normal sgwt-mini-footer-sg-label">
      &copy;
      <span className="d-none d-sm-inline-block mx-1">{props.translator.translate('corporateMention')}</span>
      <span className="d-sm-none mx-1">SG</span>
      {new Date().getFullYear()}
    </span>
  );
};
