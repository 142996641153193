export interface SgmLegalNotice {
  type: 'important-notice' | 'sgm-general-terms' | 'privacy-and-cookies' | 'cookies-consent' | 'sg-privacy-security';
  label: string;
  value: string;
}

export const COOKIES_CONSENT_MGMT: SgmLegalNotice = {
  type: 'cookies-consent',
  label: 'sgm-legal-notice.cookies-consent',
  value: '',
};

export const SGM_LEGAL_NOTICES: SgmLegalNotice[] = [
  {
    type: 'important-notice',
    label: 'sgm-legal-notice.important-notice',
    value: 'https://www.sgmarkets.com/disclaimer/file',
  },
  {
    type: 'sgm-general-terms',
    label: 'sgm-legal-notice.sgm-general-terms',
    value: 'https://content.sgmarkets.com/SGM_CGU_SGPM',
  },
  {
    type: 'privacy-and-cookies',
    label: 'sgm-legal-notice.privacy-and-cookies',
    value: 'https://content.sgmarkets.com/PP_AND_COOKIE_CHARTER',
  },
  COOKIES_CONSENT_MGMT,
  {
    type: 'sg-privacy-security',
    label: 'sgm-legal-notice.sg-privacy-security',
    value: 'https://wholesale.banking.societegenerale.com/en/information-security/',
  },
];

export const SGM_LEGAL_NOTICES_FR: SgmLegalNotice[] = [
  {
    type: 'important-notice',
    label: 'sgm-legal-notice.important-notice',
    value: 'https://www.sgmarkets.com/disclaimer/file',
  },
  {
    type: 'sgm-general-terms',
    label: 'sgm-legal-notice.sgm-general-terms',
    value: 'https://content.sgmarkets.com/SGM_CGU_SGPM_FR',
  },
  {
    type: 'privacy-and-cookies',
    label: 'sgm-legal-notice.privacy-and-cookies',
    value: 'https://content.sgmarkets.com/PP_AND_COOKIE_CHARTER_FR',
  },
  COOKIES_CONSENT_MGMT,
  {
    type: 'sg-privacy-security',
    label: 'sgm-legal-notice.sg-privacy-security',
    value: 'https://wholesale.banking.societegenerale.com/fr/securite-linformation/',
  },
];
