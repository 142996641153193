import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { ISgwtMiniFooterContactUsList, ISgwtMiniFooterLegalNotices } from '../sgwt-mini-footer.types';
import { About } from './About';
import { AccessibilityLink } from './AccessibilityLink';
import { ContactUs } from './Contact';
import { CookiesConsent, IUserConsent } from './cookies-consent';
import { LegalNotices } from './LegalNotices';
import { SGLabel } from './SGLabel';

interface CompactFooterProps extends ITranslatorProps {
  about: string;
  accessibilityCompliance?: string;
  accessibilityLink?: string;
  accessibilityUid?: string;
  contactUs?: ISgwtMiniFooterContactUsList;
  contactUsByHelpCenter?: string;
  container?: string;
  cookiesConsent: boolean;
  cookieConsentLanguage?: string;
  design?: string;
  emitUserConsentEvent: (eventName: 'user-consent-changed' | 'current-user-consent', consents: IUserConsent[]) => void;
  language: string;
  legalNotices?: ISgwtMiniFooterLegalNotices;
  microFooter: boolean;
  mode?: string;
  noBorder?: boolean;
  onAboutClicked: () => void;
  onAccessibilityClicked: () => void;
  userConnected: boolean;
}

export const CompactFooter = (props: CompactFooterProps) => {
  const { container, design, microFooter, noBorder } = props;
  let classes = `sgbs-footer-light ${typeof noBorder !== 'undefined' && noBorder ? '' : 'border-top'}`;
  if (design === 'black') {
    classes = 'sgbs-footer-black';
  } else if (design === 'dark') {
    classes = 'sgbs-footer-dark';
  }

  return (
    <footer
      role="contentinfo"
      className={`${classes} ${microFooter ? 'sgwt-micro-footer px-4 py-1' : 'sgwt-compact-footer p-3'}`}
    >
      <span className={`d-flex justify-content-between ${container === 'container' ? 'container' : ''} g-12px`}>
        <SGLabel translator={props.translator} />

        <ul className="list-unstyled d-flex mb-0">
          <AccessibilityLink
            accessibilityCompliance={props.accessibilityCompliance}
            accessibilityLink={props.accessibilityLink}
            accessibilityUid={props.accessibilityUid}
            onAccessibilityClicked={props.onAccessibilityClicked}
            translator={props.translator}
          />

          <About about={props.about} onAboutClicked={props.onAboutClicked} translator={props.translator} />

          <LegalNotices {...props} userConnected={true} />

          <ContactUs
            contactList={props.contactUs}
            contactUsByHelpCenter={props.contactUsByHelpCenter}
            translator={props.translator}
          />
        </ul>
      </span>
      {props.cookiesConsent && (
        <CookiesConsent
          key={props.language}
          emitUserConsentEvent={props.emitUserConsentEvent}
          currentLanguage={props.language}
          forcedLanguage={props.cookieConsentLanguage}
        />
      )}
    </footer>
  );
};
