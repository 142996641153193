// Even on homologation environment, we call the Production API. A "real" project has no interest in filling
// its audit results on the Homologation environment of the Accessiblity Dashboard service (#714).
export const FOOTER_ACCESSIBILITY_ENDPOINTS: Record<string, string> = {
  development: 'https://accessibility-uat.fr.world.socgen/api/v1/accessibility-statement/compliance',
  // homologation: 'https://accessibility-uat.fr.world.socgen/api/v1/accessibility-statement/compliance',
  // internalProduction: 'https://accessibility.sgmarkets.world.socgen/api/v1/accessibility-statement/compliance',
  homologation: 'https://shared.sgmarkets.com/api/v1/accessibility-statement/compliance',
  internalProduction: 'https://shared.sgmarkets.com/api/v1/accessibility-statement/compliance',
  production: 'https://shared.sgmarkets.com/api/v1/accessibility-statement/compliance',
};

export const getAccessibilityStatementPageUrl = (language: 'en' | 'fr', uid?: string) => {
  if (uid) {
    return `https://shared.sgmarkets.com/accessibility/statement-${language}.html?uid=${uid}`;
  }
  const url = window.location.protocol + '//' + window.location.host;
  return `https://shared.sgmarkets.com/accessibility/statement/${language}.html?redirect_uri=${url}`;
};
