import React from 'react';

export const SocGenLogo: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.95 28.35" height="32" width="163.61">
    <defs>
      <style>{'.cls-1{fill: #231f20;}.cls-2{fill: #ed1a3b;}.cls-3{fill: #fff;}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M47.21,3.17a12.25,12.25,0,0,0-4.6-1.07c-2.12,0-3.18.46-3.18,1.27,0,2.34,9.46.46,9.46,5.46,0,2.07-1.94,3.67-5.84,3.67a12.08,12.08,0,0,1-6.19-1.6L37.72,9a10.64,10.64,0,0,0,5.35,1.46c2.31,0,3.55-.67,3.55-1.66,0-2.56-9.46-.65-9.46-5.33,0-2,1.93-3.41,5.41-3.41a11.91,11.91,0,0,1,5.52,1.3Z"
        />
        <path
          className="cls-1"
          d="M57.76,12.57c-3.78,0-6.91-2.52-6.91-6.28S54,0,57.76,0s6.88,2.56,6.88,6.28-3.08,6.28-6.88,6.28M57.76,2a4.31,4.31,0,0,0-4.63,4.26c0,2.45,1.8,4.27,4.63,4.27s4.6-1.84,4.6-4.27A4.25,4.25,0,0,0,57.76,2"
        />
        <path
          className="cls-1"
          d="M73.67,12.56c-4,0-7-2.51-7-6.28s3-6.27,7-6.27a8.77,8.77,0,0,1,5.52,1.77L78,3.52a6.8,6.8,0,0,0-4.29-1.45c-2.9,0-4.7,1.83-4.7,4.21s1.82,4.23,4.73,4.23a7,7,0,0,0,4.35-1.43l1.17,1.74a9,9,0,0,1-5.62,1.74"
        />
        <rect className="cls-1" x="81.68" y="0.37" width="2.2" height="11.86" />
        <polygon
          className="cls-1"
          points="87.14 12.24 87.14 0.37 97.94 0.37 97.94 2.33 89.34 2.33 89.34 5.22 96.03 5.22 96.03 7.17 89.34 7.17 89.34 10.28 98.32 10.28 98.32 12.24 87.14 12.24"
        />
        <polygon
          className="cls-1"
          points="106.53 2.32 106.53 12.24 104.34 12.24 104.34 2.32 99.5 2.32 99.5 0.37 111.36 0.37 111.36 2.32 106.53 2.32"
        />
        <polygon
          className="cls-1"
          points="113.13 12.24 113.13 0.37 123.94 0.37 123.94 2.33 115.33 2.33 115.33 5.22 122.02 5.22 122.02 7.17 115.33 7.17 115.33 10.28 124.31 10.28 124.31 12.24 113.13 12.24"
        />
        <path
          className="cls-1"
          d="M43.65,26.28A8.23,8.23,0,0,0,47,25.56V22.11h2.14v4.61a10.37,10.37,0,0,1-5.62,1.63c-4,0-7-2.51-7-6.28s3-6.26,7-6.26a8.87,8.87,0,0,1,5.52,1.75L47.91,19.3a6.81,6.81,0,0,0-4.29-1.41c-2.89-.06-4.69,1.81-4.69,4.18s1.82,4.21,4.73,4.21"
        />
        <polygon
          className="cls-1"
          points="52.07 28.03 52.07 16.17 62.88 16.17 62.88 18.13 54.27 18.13 54.27 21.01 60.96 21.01 60.96 22.97 54.27 22.97 54.27 26.07 63.25 26.07 63.25 28.03 52.07 28.03"
        />
        <path
          className="cls-1"
          d="M67.57,28H65.41V16.17h2.25l7.73,8.94.05,0c-.13-2.25-.15-4.07-.15-5.82v-3.1h2.15V28H75.22l-7.71-8.93,0,0c.08,1.76.08,4.82.08,5.82Z"
        />
        <polygon
          className="cls-1"
          points="80.55 28.03 80.55 16.17 91.35 16.17 91.35 18.13 82.74 18.13 82.74 21.01 89.43 21.01 89.43 22.97 82.74 22.97 82.74 26.07 91.73 26.07 91.73 28.03 80.55 28.03"
        />
        <path
          className="cls-1"
          d="M99.8,23.66H96.25V28h-2.2V16.17h7.62c2.53,0,4.1,1.55,4.1,3.78a3.66,3.66,0,0,1-3.4,3.65L105.9,28h-2.75Zm1.64-2c1.38,0,2.15-.71,2.15-1.74a1.71,1.71,0,0,0-1.92-1.82H96.25v3.56Z"
        />
        <path
          className="cls-1"
          d="M110.41,25.15,109,28h-2.38l5.78-11.86h2.31L120.54,28h-2.37l-1.36-2.88Zm.92-1.93h4.57l-2.28-4.81Z"
        />
        <polygon
          className="cls-1"
          points="122.16 28.03 122.16 16.17 124.36 16.17 124.36 26.07 132.28 26.07 132.28 28.03 122.16 28.03"
        />
        <polygon
          className="cls-1"
          points="133.77 28.03 133.77 16.17 144.57 16.17 144.57 18.13 135.97 18.13 135.97 21.01 142.66 21.01 142.66 22.97 135.97 22.97 135.97 26.07 144.95 26.07 144.95 28.03 133.77 28.03"
        />
        <rect className="cls-2" width="28.35" height="14.46" />
        <rect className="cls-1" y="14.17" width="28.35" height="14.17" />
        <rect className="cls-3" x="4.95" y="13.31" width="18.44" height="1.72" />
      </g>
    </g>
  </svg>
);
