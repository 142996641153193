import { CONSENTS, IDidomi, IDidomiUserStatus, IUserConsent } from './didomi.types';

// Get the Didomi object, or `null` if it has not been initialized yet.
export function getDidomi(): IDidomi | null {
  return (window as any).Didomi || null;
}

// Run a function when Didomi is ready.
export function runWhenDidomiIsReady(callback: () => void) {
  (window as any).didomiOnReady = (window as any).didomiOnReady || [];
  (window as any).didomiOnReady.push(callback);
}

// Open the User Preferences popup of Didomi
export function openDidomiUserPreferences() {
  const didomi = getDidomi();
  if (didomi !== null) {
    didomi.preferences.show();
  }
}

// Find the corresponding Consent object from its Didomi API, and add the user status.
export function matchConsent(didomiId: string, status: boolean): IUserConsent {
  for (const c of CONSENTS) {
    if (didomiId === c.apiId || didomiId === c.sdkId) {
      return { ...c, consent: status };
    }
  }
  return {
    apiId: didomiId,
    sdkId: didomiId,
    consent: status,
    name: 'Unknown',
    type: 'vendor',
    category: 'other',
  };
}

// Retrieve the ID required by SGM, to remove Purposes and Vendors not interesting for us.
function getRequiredIds(): string[] {
  const didomi = getDidomi();
  // Get ID of required Vendors and Purposes
  return didomi === null ? [] : [...didomi.getRequiredVendorIds(), ...didomi.getRequiredPurposeIds()];
}

// Return the current user consent.
export function getCurrentUserConsent(): IUserConsent[] | null {
  const didomi = getDidomi();
  if (didomi === null) {
    return null;
  }
  let userConsents: IUserConsent[] = [];
  const requiredIds: string[] = getRequiredIds();
  const userStatus: IDidomiUserStatus = didomi.getUserStatus();
  if (userStatus) {
    // Retreive the Vendors and Purposes accepted or refused by the user, but keep only the ones from Required lists.
    const enabled: string[] = [...userStatus.purposes.consent.enabled, ...userStatus.vendors.consent.enabled].filter(
      (c) => requiredIds.indexOf(c) > -1,
    );

    const disabled: string[] = [...userStatus.purposes.consent.disabled, ...userStatus.vendors.consent.disabled].filter(
      (c) => requiredIds.indexOf(c) > -1,
    );

    userConsents = [...enabled.map((c) => matchConsent(c, true)), ...disabled.map((c) => matchConsent(c, false))];
  }
  return userConsents;
}
