import React from 'react';
import { IWidgetConfigurationContext } from '../../../common/configuration';
import { registerMiniFooterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { emptyObject } from '../../../common/sgwt-widgets-utils';
import { ISgwtMiniFooterContactUsList } from '../sgwt-mini-footer.types';

interface IPropsDropupItem {
  label: string;
  onClick: () => void;
  prefix?: string;
  value: string;
}

const DropupItem: React.FC<IPropsDropupItem> = ({ label, onClick, prefix, value }) => (
  <a className="dropdown-item" href={value} onClick={onClick}>
    {prefix ? <span className="font-weight-bold fw-bold text-uppercase">{`${prefix} `}</span> : ''}
    {label}
  </a>
);

interface IContactProps extends ITranslatorProps {
  contactList?: ISgwtMiniFooterContactUsList;
  contactUsByHelpCenter?: string;
}

interface IContactState {
  isOpen: boolean;
}

export class ContactUs extends React.Component<IContactProps, IContactState> {
  public context!: IWidgetConfigurationContext;
  private handler?: HTMLLIElement;

  constructor(props: IContactProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public render() {
    const label = this.props.translator.translate('contactUs');
    if (this.props.contactUsByHelpCenter !== undefined) {
      return (
        <li className="ml-3 ms-3">
          <button className="h6 font-weight-normal fw-normal sgbs-footer-item" onClick={this.openHelpCenter}>
            {label}
          </button>
        </li>
      );
    }

    const { contactList } = this.props;
    if (!contactList || emptyObject(contactList)) {
      return null;
    }

    if (
      (contactList.mail && Array.isArray(contactList.mail)) ||
      contactList.phones ||
      (contactList.links && Array.isArray(contactList.links))
    ) {
      const firstDivider = !!contactList.phones && !!contactList.links;
      const secondDivider = !firstDivider || (!!contactList.links && !!contactList.mail);
      return (
        <li
          className="ml-3 ms-3 dropup"
          ref={(handler: HTMLLIElement) => {
            this.handler = handler;
          }}
        >
          <button
            aria-expanded={this.state.isOpen}
            aria-haspopup="true"
            className="btn-flat-secondary dropdown-toggle h6 font-weight-normal fw-normal sgbs-footer-item"
            onClick={this.handleDropupToggle}
          >
            <span>{label}</span>
          </button>
          <div
            aria-labelledby={label}
            data-bs-popper
            className={`dropdown-menu dropdown-menu-right dropdown-menu-end ${this.state.isOpen && 'visible show'}`}
          >
            {contactList.phones &&
              Array.isArray(contactList.phones) &&
              contactList.phones.map((phone) => (
                <DropupItem
                  key={`phone-${phone.label}`}
                  label={phone.value}
                  onClick={this.handleClick}
                  prefix={`${phone.label}:`}
                  value={`tel:${phone.value}`}
                />
              ))}
            {firstDivider && <div className="dropdown-divider" />}
            {contactList.links &&
              Array.isArray(contactList.links) &&
              contactList.links.map((link) => (
                <DropupItem
                  key={`link-${link.label}`}
                  label=""
                  onClick={this.handleClick}
                  prefix={link.label}
                  value={link.value}
                />
              ))}
            {secondDivider && <div className="dropdown-divider" />}
            {contactList.mail && typeof contactList.mail === 'string' && (
              <DropupItem
                label={contactList.mail}
                onClick={this.handleClick}
                prefix="email:"
                value={`mailto:${contactList.mail}`}
              />
            )}
            {contactList.mail &&
              Array.isArray(contactList.mail) &&
              contactList.mail.map((mail) => (
                <DropupItem
                  key={`mail-${mail.label}`}
                  label={mail.value}
                  onClick={this.handleClick}
                  prefix={`${mail.label}:`}
                  value={`mailto:${mail.value}`}
                />
              ))}
          </div>
        </li>
      );
    }

    const clickMailContact = () => registerMiniFooterEvent('contacts.mail-link');

    const link = contactList.mail ? `mailto:${contactList.mail}` : contactList.link || '';

    return (
      <li className="ml-3 ms-3">
        <a className="h6 font-weight-normal fw-normal sgbs-footer-item" href={link} onClick={clickMailContact}>
          {label}
        </a>
      </li>
    );
  }

  private handleClick = () => {
    this.handleClose();
  };

  private handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('touchend', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.handleKeydown, false);
    this.setState({ isOpen: false });
    registerMiniFooterEvent('contacts.dropdown-close');
  };

  private handleDropupToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    const { isOpen } = this.state;
    if (isOpen) {
      this.handleClose();
    } else {
      document.addEventListener('click', this.handleOutsideClick, false);
      document.addEventListener('touchend', this.handleOutsideClick, false);
      document.addEventListener('keydown', this.handleKeydown, false);
      this.setState({ isOpen: true });
      registerMiniFooterEvent('contacts.dropdown-open');
    }
  };

  private handleKeydown = (event: KeyboardEvent) => {
    const { isOpen } = this.state;
    if (isOpen && (event.key === 'Escape' || event.which === 27)) {
      this.handleClose();
    }
  };

  private handleOutsideClick = (event: Event) => {
    if (this.handler && !this.handler.contains(event.target as Node)) {
      this.handleClose();
    }
  };

  private openHelpCenter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.handleClose(event);
    const selector = this.props.contactUsByHelpCenter || 'sgwt-help-center';
    const elt = document.querySelector(selector);
    if (elt) {
      (elt as any).form();
      registerMiniFooterEvent('contacts.open-help-center');
    } else {
      registerMiniFooterEvent('contacts.open-help-center.error');
      this.context!.widgetConfiguration.warn(
        `You have configured the sgwt-mini-footer to use the help center, but it was not found with the selector "${selector}".`,
      );
    }
  };
}
