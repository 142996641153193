/* eslint-disable @typescript-eslint/no-var-requires */
import { Translator } from '../../../common/sgwt-i18n';

const de = require('../locales/de.json');
const en = require('../locales/en.json');
const es = require('../locales/es.json');
const fr = require('../locales/fr.json');
const it = require('../locales/it.json');
const ja = require('../locales/ja.json');
const ru = require('../locales/ru.json');
const zh = require('../locales/zh.json');
const pt = require('../locales/pt.json');

const translator = new Translator();
translator.addMessages('de', de);
translator.addMessages('en', en);
translator.addMessages('es', es);
translator.addMessages('fr', fr);
translator.addMessages('it', it);
translator.addMessages('ja', ja);
translator.addMessages('jp', ja);
translator.addMessages('ru', ru);
translator.addMessages('zh', zh);
translator.addMessages('pt', pt);

export default translator;
