import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';

interface IAboutProps extends ITranslatorProps {
  about: string;
  onAboutClicked: () => void;
}

function parse(value: string): { [key: string]: any } {
  try {
    // Try default JSON parsing, working with the first two approaches.
    return JSON.parse(value);
  } catch (e) {
    try {
      // Now replace all `'` to `"` and then parse with `JSON.parse` function. Works for the 3rd approach.
      return JSON.parse(value.replace(/'/g, '"'));
    } catch (e2) {
      return {};
    }
  }
}

export const About: React.FC<IAboutProps> = (props: IAboutProps) => {
  const { about } = props;
  if (!about || about === '') {
    return null;
  }
  const parsedAbout = parse(about);
  const label = props.translator.translate('about');
  return (
    <li className="ml-3 ms-3">
      {about === 'true' ? (
        <button
          onClick={() => {
            props.onAboutClicked();
          }}
          className="h6 font-weight-normal fw-normal sgbs-footer-item"
        >
          {label}
        </button>
      ) : typeof parsedAbout === 'object' && JSON.stringify(parsedAbout) !== '{}' ? (
        parsedAbout.label && !parsedAbout.link ? (
          <button
            onClick={() => {
              props.onAboutClicked();
            }}
            className="h6 font-weight-normal fw-normal sgbs-footer-item"
          >
            {parsedAbout.label}
          </button>
        ) : (
          <a href={parsedAbout.link} className="h6 font-weight-normal fw-normal sgbs-footer-item">
            {parsedAbout.label}
          </a>
        )
      ) : (
        <a href={about} className="h6 font-weight-normal fw-normal sgbs-footer-item">
          {label}
        </a>
      )}
    </li>
  );
};
