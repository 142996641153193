// Basic Didomi object, extract from https://developers.didomi.io/cmp/web-sdk/reference/api
export interface IDidomi {
  preferences: {
    show: (view?: 'information' | 'purposes' | 'vendors') => void;
  };
  getRequiredPurposeIds: () => string[];
  getRequiredVendorIds: () => string[];
  getUserStatus: () => IDidomiUserStatus;
}

// Description of a Consent object from Didomi configuration.
interface ICookiesConsent {
  apiId: string;
  sdkId: string;
  name: string;
  category: 'web-analytics' | 'video' | 'technical' | 'other';
  type: 'purpose' | 'vendor';
}

// ...and with the user consent information.
export interface IUserConsent extends ICookiesConsent {
  consent: boolean;
}

interface IDidomiUserStatusConsent {
  enabled: string[];
  disabled: string[];
}

export interface IDidomiUserStatus {
  user_id: string;
  purposes: {
    consent: IDidomiUserStatusConsent;
    // legitimate_interest: IDidomiUserStatusConsent; // Not used in Didomi config for SGM
  };
  vendors: {
    consent: IDidomiUserStatusConsent;
    // legitimate_interest: IDidomiUserStatusConsent; // Not used in Didomi config for SGM
  };
}

// Didomi Purposes and Vendors used on our side.
export const CONSENTS: ICookiesConsent[] = [
  // -------- PURPOSES --------
  {
    // SG Markets technical cookie
    apiId: 'cookieste-Mpq6pxUD',
    sdkId: 'cookieste-Mpq6pxUD',
    name: 'SG Markets',
    category: 'technical',
    type: 'purpose',
  },
  {
    // Web analytics
    apiId: 'mesureda-hNwQmpx8',
    sdkId: 'mesureda-hNwQmpx8',
    name: 'Web Analytics',
    category: 'web-analytics',
    type: 'purpose',
  },
  {
    // Video
    apiId: 'video-dtBpce2R',
    sdkId: 'video-dtBpce2R',
    name: 'Video',
    category: 'video',
    type: 'purpose',
  },
  // -------- VENDORS --------
  {
    // Matomo
    apiId: 'sgmarkets-3TDrCkUW',
    sdkId: 'c:sgmarkets-3TDrCkUW',
    name: 'Web Analytics',
    category: 'web-analytics',
    type: 'vendor',
  },
  {
    // Qumu
    apiId: 'qumu-XmcMMVne',
    sdkId: 'c:qumu-XmcMMVne',
    name: 'Qumu',
    category: 'video',
    type: 'vendor',
  },
];

// Event when the consent changes.
export const DIDOMI_EVENT_CONSENT_CHANGED = 'consent.changed';

// Events used on our monitoring.
export const DIDOMI_EVENTS_TO_MONITOR = [
  'consent.changed',
  'notice.clickagree',
  'preferences.clickdisagreetoall',
  'preferences.clicksavechoices',
  'notice.shown',
  'notice.hidden',
];
