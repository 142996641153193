import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../../common/configuration';
import { registerMiniFooterEvent } from '../../../../common/monitoring';
import { isProductionEnvironment } from '../../../../common/sgwt-widgets-utils';
import { getCurrentUserConsent, runWhenDidomiIsReady } from './didomi.service';
import { DIDOMI_EVENT_CONSENT_CHANGED, DIDOMI_EVENTS_TO_MONITOR, IUserConsent } from './didomi.types';

interface CookiesConsentProps {
  emitUserConsentEvent: (eventName: 'user-consent-changed' | 'current-user-consent', consents: IUserConsent[]) => void;
  currentLanguage: string;
  forcedLanguage?: string;
}

interface CookiesConsentState {
  waitBeforeLoading: boolean;
}

const DIDOMI_SCRIPT_HOM = 'widgets/sgwt-mini-footer/didomi/didomi-script.v2.min.js';
const DIDOMI_SCRIPT_PROD = 'widgets/sgwt-mini-footer/didomi/didomi-script.min.js';
const CDN_SHARED_DOMAIN = 'https://shared.sgmarkets.com';
const CDN_AZURE_DEV_DOMAIN = 'https://sgwt-widgets-dev.sgmarkets.com';
const CDN_AZURE_PRD_DOMAIN = 'https://sgwt-cdn-widgets.sgmarkets.com';
// We load the Didomi script with a short delay. This is done to prevent the Didomi script to be run
// on some specific pages: login page with automatic logon, index page used to renew token...
const DIDOMI_LOADING_DELAY = 500;

export class CookiesConsent extends React.Component<CookiesConsentProps, CookiesConsentState> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;
  public state: CookiesConsentState = {
    waitBeforeLoading: true,
  };

  constructor(props: CookiesConsentProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
  }

  /**
   * We only manage `en` and `fr` languages for the Didomi widget.
   * TODO Due to a limitation of Didomi API, it's not possible to change the language dynamically
   * An `updateSelectedLanguage` function exists, but is not available on the web version (only mobile)
   * https://developers.didomi.io/cmp/mobile-sdk/react-native/reference#updateselectedlanguage
   *
   * By default, we allow both 'en' and 'fr', and let Didomi uses the Browser preference to choose the
   * language to apply. Services that want to force one of this language can use the attribute
   * `cookie-consent-popup-language` of the widget, set either to `en` or `fr`.
   */
  getLanguagesConfig() {
    if (this.props.forcedLanguage) {
      const forced = this.props.forcedLanguage.toLowerCase();
      if (['en', 'fr'].includes(forced)) {
        return {
          enabled: [forced.toLowerCase()],
          default: forced.toLowerCase(),
        };
      }
      console.warn(
        `[sgwt-mini-footer] The "cookie-consent-popup-language" attribute only accepts "fr" or "en", so "${forced}" is invalid.`,
      );
    }
    return {
      enabled: ['fr', 'en'],
      default: 'en',
    };
  }

  componentDidMount() {
    // Configuration, cf. https://console.didomi.io/societe-generale-cib/notices/NQ268Jbg/embed
    (window as any).didomiConfig = {
      languages: this.getLanguagesConfig(),
      // TODO Remove error "Didomi - IDs configuration for the IAB stacks should be a non-empty array"
      app: {
        vendors: {
          iab: {
            stacks: {
              ids: [1],
            },
          },
        },
      },
      // Exclude bots from consent: https://developers.didomi.io/cmp/web-sdk/consent-notice/bots
      user: {
        bots: {
          consentRequired: false,
        },
      },
    };
    // Listen to Didomi events
    runWhenDidomiIsReady(() => {
      this.listenToDidomiEvents();
      this.retrieveCurrentUserConsent();
    });
    setTimeout(() => {
      this.setState({ waitBeforeLoading: false });
    }, DIDOMI_LOADING_DELAY);
  }

  private listenToDidomiEvents = () => {
    const listeners = (window as any).didomiEventListeners;
    if (listeners) {
      // Event related to consent modification...
      listeners.push({
        event: DIDOMI_EVENT_CONSENT_CHANGED,
        listener: this.consentChanged,
      });

      // Events we just want to monitor in our activity logs...
      for (const evt of DIDOMI_EVENTS_TO_MONITOR) {
        listeners.push({
          event: evt,
          listener: () => registerMiniFooterEvent(`cookies-consent.didomi.${evt}`),
        });
      }
    }
  };

  private consentChanged = () => {
    const consents = getCurrentUserConsent();
    this.props.emitUserConsentEvent('user-consent-changed', consents || []);
    registerMiniFooterEvent('cookies-consent.consent-changed');
  };

  private retrieveCurrentUserConsent = () => {
    const consents = getCurrentUserConsent();
    this.props.emitUserConsentEvent('current-user-consent', consents || []);
  };

  // Check on which CDN the Widget JS was loaded, to either user the Azure or Shared CDN to load the Didomi JS script.
  private getCdnUrl() {
    // We search for the <script> tag that loaded the widget...
    const scriptTag = document.querySelector(`script[src*="sgwt-mini-footer.js"]`);
    const didomiScript = isProductionEnvironment(this.widgetConfiguration) ? DIDOMI_SCRIPT_PROD : DIDOMI_SCRIPT_HOM;
    if (scriptTag) {
      const src = (scriptTag as HTMLScriptElement).src;
      // and check if it was loaded from one Azure CDN...
      if (src.indexOf(CDN_AZURE_PRD_DOMAIN) > -1 || src.indexOf(CDN_AZURE_DEV_DOMAIN) > -1) {
        return `${CDN_AZURE_PRD_DOMAIN}/${didomiScript}`;
      }
    }
    return `${CDN_SHARED_DOMAIN}/${didomiScript}`;
  }

  render() {
    if (!this.state.waitBeforeLoading) {
      const cdnUrl = this.getCdnUrl();
      addScript(cdnUrl);
    }
    return null;
  }
}

function addScript(url: string, type = 'text/javascript'): HTMLScriptElement | undefined {
  if (!document.getElementById(url)) {
    const script = document.createElement('script');
    script.type = type;
    script.src = url;
    script.id = url;
    document.head.appendChild(script);
    return script;
  }
  return undefined;
}
