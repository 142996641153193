import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { ISgwtMiniFooterContactUsList, ISgwtMiniFooterLegalNotices } from '../sgwt-mini-footer.types';
import { About } from './About';
import { AccessibilityLink } from './AccessibilityLink';
import { ContactUs } from './Contact';
import { CookiesConsent, IUserConsent } from './cookies-consent';
import { LegalNotices } from './LegalNotices';
import { SGLabel } from './SGLabel';
import { SocGenLogo } from './SocGenLogo';

export interface MediumFooterProps extends ITranslatorProps {
  about: string;
  accessibilityCompliance?: string;
  accessibilityLink?: string;
  accessibilityUid?: string;
  contactUs?: ISgwtMiniFooterContactUsList;
  contactUsByHelpCenter?: string;
  container?: string;
  cookiesConsent: boolean;
  cookieConsentLanguage?: string;
  design?: string;
  emitUserConsentEvent: (eventName: 'user-consent-changed' | 'current-user-consent', consents: IUserConsent[]) => void;
  language: string;
  legalNotices?: ISgwtMiniFooterLegalNotices;
  logoLink?: string;
  logoLinkTitle?: string;
  mode?: string;
  noBorder?: boolean;
  onAboutClicked: () => void;
  onAccessibilityClicked: () => void;
  userConnected: boolean;
}

export const MediumFooter = (props: MediumFooterProps) => {
  const { logoLink } = props;
  const bg = props.design === 'alt' ? 'bg-light-2' : 'bg-light-1';
  const border = typeof props.noBorder !== 'undefined' && props.noBorder ? '' : 'border-bottom border-light';

  return (
    <footer role="contentinfo" className={`sgbs-footer-light sgwt-medium-footer text-secondary ${bg}`}>
      <div className={`d-flex flex-column ${props.container === 'container' ? 'container' : 'mx-4'} g-12px`}>
        <div className={`py-4 d-flex justify-content-between ${border}`}>
          {logoLink && logoLink !== '' ? (
            <a href={logoLink} title={props.logoLinkTitle}>
              <SocGenLogo />
            </a>
          ) : (
            <SocGenLogo />
          )}
        </div>
        <div className="sgbs-footer-light bg-black-alt d-flex justify-content-between py-3">
          <SGLabel translator={props.translator} />

          <ul className="list-unstyled d-flex mb-0">
            <AccessibilityLink
              accessibilityCompliance={props.accessibilityCompliance}
              accessibilityLink={props.accessibilityLink}
              accessibilityUid={props.accessibilityUid}
              onAccessibilityClicked={props.onAccessibilityClicked}
              translator={props.translator}
            />

            <About about={props.about} onAboutClicked={props.onAboutClicked} translator={props.translator} />

            <LegalNotices {...props} userConnected={true} />

            <ContactUs
              contactList={props.contactUs}
              contactUsByHelpCenter={props.contactUsByHelpCenter}
              translator={props.translator}
            />
          </ul>
        </div>
      </div>
      {props.cookiesConsent && (
        <CookiesConsent
          emitUserConsentEvent={props.emitUserConsentEvent}
          currentLanguage={props.language}
          forcedLanguage={props.cookieConsentLanguage}
        />
      )}
    </footer>
  );
};
